import axios from 'axios'
import store from '@/store'
import { parseErrorResponseToArray } from '@/utils'

const instance = axios.create({})

const contentful = require('contentful')
const client = contentful.createClient({
  // This is the space ID. A space is like a project folder in Contentful terms
  space: 'r5zywbfuaffa',
  // This is the access token for this space. Normally you get both ID and the token in the Contentful web app
  accessToken: 'renuYAsugospIKv7qyhfoYP5a66VMlDlDJ6z7Cj8WDQ'
})

// Handle global errors
const errorComposer = (error) => {
  return () => {
    const array = parseErrorResponseToArray(error)
    const data = { errors: array }
    store.dispatch('setErrors', data)
  }
}

instance.interceptors.request.use((config) => {
  return config
}, (error) => {
  error.handleGlobally = errorComposer(error)
  return Promise.reject(error)
})

/**
 * Get common enties
 */
const getEntries = async () => {
  try {
    return await getEntriesByType('helpCenterEntry')
  } catch (error) {
    return Promise.reject(error)
  }
}

/**
 * Get entries from endpoint
 */
const getEntriesByType = async (contentType) => {
  try {
    const response = await instance.get('https://cdn.contentful.com/spaces/r5zywbfuaffa/environments/master/entries', {
      params: {
        access_token: 'renuYAsugospIKv7qyhfoYP5a66VMlDlDJ6z7Cj8WDQ',
        content_type: contentType
      }
    })
    return response.data
  } catch (error) {
    return Promise.reject(error)
  }
}

/**
 * Get entry by slug
 */
const getEntryBySlug = async slug => {
  try {
    const response = await client.getEntries({
      content_type: 'helpCenterEntry',
      limit: 1,
      include: 10,
      'fields.slug': slug
    })
    return response
  } catch (error) {
    return Promise.reject(error)
  }
}

/**
 * Get home highlight
 */
const getHomeHighlight = async () => {
  try {
    return await getEntriesByType('homeHighlight')
  } catch (error) {
    return Promise.reject(error)
  }
}

const searchEntriesByTerm = async term => {
  try {
    const response = await instance.get('https://cdn.contentful.com/spaces/r5zywbfuaffa/environments/master/entries', {
      params: {
        access_token: 'renuYAsugospIKv7qyhfoYP5a66VMlDlDJ6z7Cj8WDQ',
        query: term
      }
    })
    return response.data
  } catch (error) {
    return Promise.reject(error)
  }
}

export default {
  getEntries,
  getEntryBySlug,
  getHomeHighlight,
  searchEntriesByTerm
}
